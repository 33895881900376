import React, { useState } from 'react';
import Confetti from 'react-confetti';
import './App.css';

function App() {
  const [showConfetti, setShowConfetti] = useState(false);
 
  return (
    <div className="App">
      {showConfetti && <Confetti width={window.innerWidth} height={window.innerHeight} />}
      <div className="text-div">Welcome to BlerkRerk $BLRK</div>
      <div className="text-div1">We Will Consume All Meme Coins</div>
      <div className="text-div2">© 2024 BlerkRerk. All Rights Reserved.</div>
      <button
        className="buy-button"
        onMouseEnter={() => setShowConfetti(true)}
        onMouseLeave={() => setShowConfetti(false)}
        onClick={() => window.location.href = 'https://pump.fun/coin/HZ1o1G6YpqYsSKokeF4xtm2oEkqesroqXSAohzGJpump'}
      >
        Buy $BLRK
      </button>
      <div
        className="social-links"
        style={{
          position: 'absolute',
          left: '20px',
          top: '90%',
          transform: 'translateY(-50%)',
          display: 'inline-block',
          backgroundColor: '#000000',
          color: 'white',
          padding: '10px 20px',
          textAlign: 'center',
          textDecoration: 'none',
          fontSize: '16px',
          fontWeight: 'bold',
          border: 'none',
          borderRadius: '25px',
          transition: 'background-color 0.3s, box-shadow 0.3s',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          cursor: 'pointer',
        }}
        onMouseEnter={(e) => {
          e.target.style.backgroundColor = '#000000';
          e.target.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.3)';
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = '#000000';
          e.target.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
        }}
        onMouseDown={(e) => {
          e.target.style.backgroundColor = '#000000';
          e.target.style.boxShadow = '0 3px 6px rgba(0, 0, 0, 0.2)';
        }}
        onMouseUp={(e) => {
          e.target.style.backgroundColor = '#000000';
          e.target.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.3)';
        }}
      >
        <a
          href="https://x.com/blerkrerkSOL"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: 'white',
            textDecoration: 'none',
          }}
        >
          Follow @BlerkRerk on X
        </a>
      </div>
    </div>
  );
}

export default App;